<template>
  <svg
    fill="none"
    height="125"
    viewBox="0 0 132 125"
    width="132"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_244_269)">
      <path
        class="v-ranged-box-inside"
        d="M28.3261 77.4443L28.4645 41.8617L70.4746 33.7173L69.9412 95.7025L59.7309 99.5807L28.3261 77.4443Z"
      />
      <path
        class="v-ranged-box-inside-alt"
        d="M104.673 48.4287L101.99 83.5352L69.9412 95.7026L70.4746 33.7174L104.673 48.4287Z"
      />
      <path
        class="v-ranged-primary-background-color-variant"
        d="M28.466 41.8616L59.9314 59.5639L59.7324 99.5807L28.3276 77.4442L28.466 41.8616Z"
      />
      <path
        class="v-ranged-primary-background-color-variant-dark"
        d="M104.676 48.4287L101.993 83.5352L59.7333 99.5808L59.9323 59.564L104.676 48.4287Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M59.8237 80.5879L59.8144 82.4212L28.4007 57.9648L28.4095 56.1283L59.8237 80.5879Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M59.8037 84.6516L59.7944 86.4849L28.3844 62.0312L28.3932 60.1947L59.8037 84.6516Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M28.3747 64.2621L59.7842 88.7125L59.7749 90.5458L28.3691 66.0982L28.3747 64.2621Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M28.3242 77.4446L28.3268 76.4659L41.6842 86.8643L28.3242 77.4446Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M59.8466 76.5232L59.8373 78.3565L28.4166 53.8978L28.4255 52.0613L59.8466 76.5232Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M59.9055 64.3313L59.8962 66.1646L29.2397 42.2988L37.763 47.0933L59.9055 64.3313Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M28.3531 70.1651L28.3587 68.3291L59.7613 92.7772L59.752 94.6105L28.3531 70.1651Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M28.3378 74.2316L28.3434 72.3955L59.7423 96.8408L59.733 98.6741L28.3378 74.2316Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M59.9278 60.2668L59.9185 62.1001L48.1348 52.927L56.6581 57.7215L59.9278 60.2668Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M59.8856 68.3954L59.8763 70.2287L28.451 45.7608L28.4571 43.9279L59.8856 68.3954Z"
      />
      <path
        class="v-ranged-primary-background-color"
        d="M59.8656 72.4591L59.8563 74.2924L28.4319 49.8309L28.4408 47.9944L59.8656 72.4591Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M59.8537 74.9105L86.9047 52.8512L90.2051 52.0303L59.8441 76.7898L59.8537 74.9105Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M59.8311 79.0733L94.2215 51.0307L97.5215 50.2065L59.8216 80.9526L59.8311 79.0733Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M59.8124 83.2356L101.539 49.2068L104.673 48.4287L104.664 48.5285L59.8029 85.1149L59.8124 83.2356Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M59.7899 87.3983L104.479 50.9541L104.326 52.9485L59.7804 89.2776L59.7899 87.3983Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M59.8944 66.5848L72.2699 56.4919L75.5703 55.6709L59.8845 68.4609L59.8944 66.5848Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M68.2539 57.4917L59.9075 64.2983L59.9174 62.4222L64.9507 58.3163L68.2539 57.4917Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M59.8757 70.7474L79.5873 54.6715L82.8877 53.8506L59.8657 72.6235L59.8757 70.7474Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M88.9665 88.4815L102.453 77.4819L102.301 79.4796L93.259 86.8493L88.9665 88.4815Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M79.4532 92.0922L102.79 73.062L102.638 75.0564L83.7462 90.4632L79.4532 92.0922Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M101.99 83.535L98.4792 84.8679L102.113 81.9058L101.99 83.535Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M59.7675 91.5612L104.142 55.3774L103.989 57.3719L59.758 93.4405L59.7675 91.5612Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M59.7487 95.7238L103.805 59.7979L103.652 61.7923L59.7392 97.6031L59.7487 95.7238Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M69.9408 95.7024L103.128 68.6416L102.976 70.636L74.2338 94.0733L69.9408 95.7024Z"
      />
      <path
        class="v-ranged-primary-background-color-darken"
        d="M60.4284 99.316L103.465 64.2183L103.313 66.2159L64.7214 97.687L60.4284 99.316Z"
      />
      <path
        class="v-ranged-secondary"
        d="M87.6611 52.6963L84.7026 90.054L78.3313 92.3928L80.1334 54.4542L87.6611 52.6963Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          class="v-ranged-primary-background-color-lighten"
          d="M87.6611 52.6963L84.7026 90.054L86.3018 89.562L87.6611 52.6963Z"
        />
      </g>
      <path
        class="v-ranged-secondary"
        d="M44.7986 50.877L44.2337 88.814L39.5184 85.1075L39.5462 48.0661L44.7986 50.877Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          class="v-ranged-primary-background-color-lighten"
          d="M44.7988 50.877L44.2339 88.814L45.6682 89.8223L44.7988 50.877Z"
        />
      </g>
      <path
        class="v-ranged-primary-background-color-variant"
        d="M14.7969 73.9504L59.8657 40.8053L60.6314 25.9473L61.4046 10.9766L51.6151 0.869076L12.4748 32.9617L2.71769 63.4165L14.7969 73.9504Z"
      />
      <path
        class="v-ranged-primary-background-color-variant-dark"
        d="M14.797 73.9506L59.8658 40.8055L46.5385 30.196L2.71783 63.4167L14.797 73.9506Z"
      />
      <path
        class="v-ranged-primary-background-color-lighten"
        d="M46.5386 30.1959L51.6152 0.869141L12.475 32.9617L2.71787 63.4166L46.5386 30.1959Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          class="v-ranged-primary-background-color-lighten"
          d="M60.6322 25.9474L48.8688 16.279L7.3153 49.1478L49.0424 17.2398L60.6322 25.9474Z"
        />
      </g>
      <path
        class="v-ranged-secondary"
        d="M48.8689 16.2789L60.6323 25.9473L60.9588 20.501L49.8929 10.7321L9.42341 42.1431L7.31536 49.1476L48.8689 16.2789Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          class="v-ranged-primary-background-color-lighten"
          d="M31.6554 17.1829L26.5239 45.2368L38.9863 56.1704L27.3132 44.5851L31.6554 17.1829Z"
        />
      </g>
      <path
        class="v-ranged-secondary"
        d="M38.9863 56.1704L26.5239 45.2368L31.6554 17.1829L25.3271 22.3721L20.2434 50.1042L32.9888 60.67L38.9863 56.1704Z"
      />
      <path
        class="v-ranged-secondary-lighten"
        d="M20.6361 52.0387C20.6361 52.0387 25.4428 50.5581 27.1663 52.2649C27.8868 54.0163 29.5565 59.7787 19.3621 67.0726C9.17096 74.366 2.99052 66.1523 2.54471 63.2136C4.25004 64.8376 8.21299 66.6822 9.82527 66.3558C6.18202 64.2171 7.43616 60.6312 7.43616 60.6312C7.43616 60.6312 12.4115 69.3148 17.98 65.9158C23.5448 62.5139 23.7925 53.628 20.6366 52.0419L20.6361 52.0387Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M26.3893 35.1201C26.3893 35.1201 18.3432 42.8468 18.7626 46.5936C19.182 50.3403 26.142 48.9964 27.458 52.244C28.265 54.2361 27.9639 56.3497 27.3953 57.4467C28.0592 55.1578 27.5353 52.9884 27.2087 52.3542C25.5696 51.6567 24.4431 51.3376 20.6787 52.082C20.5334 52.0366 15.1704 48.0757 15.0516 47.7212C14.9323 47.3636 15.5303 44.7686 15.5303 44.7686L19.5513 38.9783L22.9357 37.2165L26.393 35.1229L26.3893 35.1201Z"
          fill="#CCCCCC"
        />
      </g>
      <path
        class="v-ranged-secondary"
        d="M17.984 45.7996C17.7738 50.3236 25.499 48.7483 27.2313 52.3712C23.9502 49.4828 15.5909 55.7461 12.3222 49.4316C7.37078 39.8746 23.0917 37.3594 24.6677 34.4214C24.9316 35.0806 25.0855 34.9738 26.0735 35.1776C24.1615 37.4139 18.1133 43.0467 17.984 45.7996Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M25.804 34.9324C19.4887 38.6949 10.8425 36.961 5.69613 33.0585C14.7813 33.6445 25.804 34.9324 25.804 34.9324Z"
          fill="#CCCCCC"
        />
      </g>
      <path
        class="v-ranged-secondary-variant"
        d="M25.9025 34.7579C25.9025 34.7579 13.0131 43.0379 -3.50869 25.6964L1.37658 15.5406C1.37658 15.5406 9.85472 31.8391 25.9058 34.7575L25.9025 34.7579Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M25.8036 34.9327C25.8036 34.9327 16.7867 24.3629 -3.52686 25.8336C3.0553 23.9153 16.395 20.5301 25.8036 34.9327Z"
          fill="#CCCCCC"
        />
      </g>
      <path
        class="v-ranged-secondary"
        d="M25.8048 34.9323C25.8048 34.9323 24.8726 20.498 1.33008 15.5601C-1.87043 20.6845 -3.5256 25.8333 -3.5256 25.8333C-3.5256 25.8333 14.9263 22.8752 25.8048 34.9323Z"
      />
      <path
        class="v-ranged-secondary-variant"
        d="M34.4728 59.721C34.4728 59.721 33.5418 57.0994 36.0421 54.4069C39.8976 50.2549 49.6601 47.2237 53.983 59.3471C57.8602 70.2192 43.4447 74.8607 43.4447 74.8607C43.4447 74.8607 43.4837 71.6968 46.0442 69.4358C41.6212 68.7782 39.9717 66.3913 39.9717 66.3913C39.9717 66.3913 45.0605 65.9614 47.7498 63.9845C50.0849 62.2672 48.899 58.2201 45.8942 58.028C40.322 57.6716 42.1458 63.1861 42.1458 63.1861L34.4728 59.721Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M24.6327 33.9529C24.6327 33.9529 35.7642 33.5283 43.6754 41.1495C50.0811 47.3225 47.7025 61.4104 42.1428 63.1863C44.7981 59.5398 46.9968 50.7399 44.5138 46.1939C42.0308 41.6478 38.0525 37.1755 33.8978 36.3295C29.7431 35.4834 24.6327 33.9529 24.6327 33.9529Z"
          fill="#CCCCCC"
        />
      </g>
      <path
        class="v-ranged-secondary"
        d="M25.1468 35.2702C25.1468 35.2702 37.8784 37.4954 39.9808 44.9147C42.08 52.3344 36.8846 58.3638 34.5391 59.8167C36.9585 63.5738 42.1463 63.1892 42.1463 63.1892C42.1463 63.1892 49.2409 58.0377 45.9186 46.494C42.5964 34.9503 24.6329 33.9563 24.6329 33.9563L25.1473 35.2734L25.1468 35.2702Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M25.6699 35.1971C25.6699 35.1971 21.2262 31.0655 21.267 25.2781C22.0636 29.5239 24.5962 34.1648 25.6699 35.1971Z"
          fill="#CCCCCC"
        />
      </g>
      <g style="mix-blend-mode: multiply">
        <path
          d="M30.2881 17.7803C30.2881 17.7803 26.3354 25.7706 25.6843 33.8756C23.5512 30.8436 27.0198 18.8791 30.2881 17.7803Z"
          fill="#CCCCCC"
        />
      </g>
      <path
        class="v-ranged-secondary-variant"
        d="M25.6774 35.2059C25.6774 35.2059 14.0089 24.1289 28.0377 3.60151L39.7375 6.21013C39.7375 6.21013 24.8639 18.4174 25.6774 35.2059Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M39.7055 6.17229C39.7055 6.17229 43.6354 24.7854 27.1854 34.3461C32.6303 19.135 29.5346 10.5474 27.9089 3.67567C31.1195 3.63549 39.7055 6.17229 39.7055 6.17229Z"
          fill="#CCCCCC"
        />
      </g>
      <path
        class="v-ranged-secondary"
        d="M25.6691 35.197C25.6691 35.197 40.2144 30.9413 39.7052 6.17177C33.6245 4.1133 27.8903 3.61531 27.8903 3.61531C27.8903 3.61531 35.5691 21.5387 25.6723 35.1966L25.6691 35.197Z"
      />
      <g style="mix-blend-mode: multiply">
        <path
          d="M25.0086 33.7991C23.0063 34.1043 23.4867 36.1518 25.9229 35.5399C28.359 34.928 27.4775 33.4223 25.0086 33.7991Z"
          fill="#CCCCCC"
        />
      </g>
      <path
        class="v-ranged-secondary"
        d="M25.0867 33.7885C23.4576 34.0417 23.9024 36.0941 25.8808 35.546C27.8593 34.9978 27.0979 33.4755 25.0867 33.7885Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_244_269">
        <rect
          fill="white"
          height="100"
          transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 105 0)"
          width="105"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style lang="scss">
@use 'assets/variables';

.v-ranged-primary-background-color {
  fill: variables.$PrimaryBackgroundColor;
}

.v-ranged-primary-background-color-darken {
  fill: variables.$PrimaryBackgroundColorDarken;
}

.v-ranged-primary-background-color-lighten {
  fill: variables.$PrimaryBackgroundColorLighten;
}

.v-ranged-primary-background-color-variant-dark {
  fill: variables.$PrimaryBackgroundColorDarkest;
}

.v-ranged-primary-background-color-variant {
  fill: variables.$PrimaryBackgroundColorLightest;
}

.v-ranged-box-inside {
  fill: variables.$BodyTextColor;
}

.v-ranged-box-inside-alt {
  fill: variables.$BodyTextColorLight;
}

.v-ranged-secondary {
  fill: variables.$SecondaryBackgroundColor;
}

.v-ranged-secondary-variant {
  fill: variables.$SecondaryBackgroundColorLightest;
}

.v-ranged-secondary-lighten {
  fill: variables.$SecondaryBackgroundColorLighten;
}
</style>
